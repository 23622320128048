<template>
  <div class="Integral  ">
    <div class="   ">

      <dl>
        <dt></dt>
        <dd>

        </dd>
      </dl>
      <div class="list">
        <table>
          <tr>
            <td>议价产品</td>

            
             <td>提交时间</td>
            <td>操作</td>
          </tr>
          <tr
            class="masthead "
            v-for='(item,index) in list'
            :key='index'
          >
            <td>{{item.goodname}} </td>

            
            <td>{{new Date(item.add_date).format('yyyy-MM-dd') }}</td>

            <td>
              <el-button
                type="primary"
                size="mini"
                @click="handleEdit(item)"
              >
                查看
              </el-button>
              <el-button
                size="mini"
                type="danger"
                @click="handleDelect(item)"
              >
                删除
              </el-button>

            </td>
          </tr>

        </table>
      </div>

    </div>
    <div style="text-align:center;margin-top:20px">
      <el-pagination
        @current-change="currentChange"
        :current-page.sync="searchData.page"
        :page-sizes="[10, 20, 30, 40]"
        :page-size.sync="searchData.limit"
        :total="searchData.total"
      >
      </el-pagination>
    </div>

  </div>
</template>

<script type="text/javascript">
// import this.$config from '../../../this.$config/this.$config'
export default {
  data() {
    return {
      list: [],
      total: 0,
      page: 1,
      limit: 20,
      searchData: {
        member_id: this.$store.state.member.id,
        page: 1,
        limit: 10
      }
    };
  },
  filters: {},
  methods: {
    handleDelect(item) {
      this.$del("home/goodmessage/" + item.id).then(res => {
        this.$message.success("删除成功！");
        this.currentChange();
      });
    },

    handleEdit(item) {
      this.$router.push({
        path: "member-consult-edit",
        query: { id: item.id }
      });
    },

    currentChange() {
      this.$get("seller/goodmessage", this.$searchData).then(res => {
        console.log("res11", res);
        this.list = res.items.data;
        this.searchData.total = res.total;
      });
    }
  },
  components: {},
  created() {
    this.currentChange();
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixins";
dl {
  @include space-between;
  margin-bottom: 10px;
}
table {
  tr:nth-child(1) {
    width: 30%;
    background-color: #f1f1f1;
  }
  width: 100%;
  border-collapse: collapse;
  td {
    text-align: center;
    padding: 10px;
    border-bottom: 1px solid #f1f1f1;
  }
}
.list {
  min-height: 600px;
}
</style>
